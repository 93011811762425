import React from 'react'
import styles from './button.module.scss'
import cn from 'classnames'
import Link from 'components/common/SmartLink'

const ButtonContent = ({name}) => (
    <>
        {name}
        <span className={styles['loader']}>
            <svg width="32" height="32" viewBox="0 0 32 32">
                <g>
                    <path className={styles['u_circle']} d="M16 0a.938.938 0 0 0-.937.938v6.025a.938.938 0 0 0 1.875 0V.937A.938.938 0 0 0 16 0z"/>
                    <path className={styles['u_circle']} d="M24 2.144a.937.937 0 0 0-1.28.343l-3.013 5.218a.937.937 0 1 0 1.624.937l3.012-5.218A.937.937 0 0 0 24 2.144z"/>
                    <path className={styles['u_circle']} d="M29.856 8a.937.937 0 0 0-1.28-.343l-5.218 3.013a.937.937 0 1 0 .938 1.623l5.217-3.012A.937.937 0 0 0 29.856 8z"/>
                    <path className={styles['u_circle']} d="M31.063 15.063h-6.025a.938.938 0 0 0 0 1.875h6.024a.938.938 0 0 0 0-1.875z"/>
                    <path className={styles['u_circle']} d="M29.513 22.72l-5.217-3.013a.937.937 0 1 0-.938 1.624l5.218 3.012a.937.937 0 0 0 .938-1.624z"/>
                    <path className={styles['u_circle']} d="M24.343 28.576l-3.012-5.218a.937.937 0 1 0-1.624.938l3.012 5.217a.937.937 0 1 0 1.624-.937z"/>
                    <path className={styles['u_circle']} d="M16 24.1a.938.938 0 0 0-.937.938v6.024a.938.938 0 0 0 1.875 0v-6.024A.937.937 0 0 0 16 24.1z"/>
                    <path className={styles['u_circle']} d="M11.95 23.015a.937.937 0 0 0-1.28.343l-3.013 5.218a.937.937 0 0 0 1.624.937l3.012-5.217a.937.937 0 0 0-.343-1.281z"/>
                    <path className={styles['u_circle']} d="M8.985 20.05a.937.937 0 0 0-1.28-.343l-5.218 3.012a.937.937 0 0 0 .937 1.624l5.218-3.012a.937.937 0 0 0 .343-1.28z"/>
                    <path className={styles['u_circle']} d="M6.963 15.063H.937a.938.938 0 0 0 0 1.875h6.026a.938.938 0 0 0 0-1.875z"/>
                    <path className={styles['u_circle']} d="M8.642 10.67L3.424 7.656a.937.937 0 1 0-.937 1.624l5.218 3.012a.937.937 0 0 0 .938-1.624z"/>
                    <path className={styles['u_circle']} d="M12.293 7.705L9.281 2.487a.937.937 0 1 0-1.624.937l3.012 5.218a.937.937 0 1 0 1.624-.937z"/>
                </g>
            </svg>
        </span>
    </>
);

const Button = ({type='button', name = 'Button', loading = false, href = false, to='/', color = null, size = null, iconName = null, rightIcon = false, disabled = false, onClick}) => {
    if (href && to) {
        return (
            <Link
                type={type}
                className={cn(
                    {[styles['loading']]: loading},
                    {[styles[`color_${color}`]]: color},
                    {[styles[`size_${size}`]]: size},
                    {[styles[`icon_${iconName}`]]: iconName})}
                disabled={disabled}
                title={name}
                to={to}
            >
                <ButtonContent name={name} />
            </Link>)
    }

    return (
        <button
            type={type}
            className={cn(
                {[styles['loading']]: loading},
                {[styles[`color_${color}`]]: color},
                {[styles[`size_${size}`]]: size},
                {[styles[`icon_${iconName}`]]: iconName})}
            disabled={disabled}
            onClick={onClick}>
            <ButtonContent name={name} />
        </button>
    )
};

export default Button;
