import {Field} from 'react-final-form'
import React from "react";
import {useTranslation} from "react-i18next";

export const validator = {
    required: value => (value ? undefined : 'FaqForm_field_required'),
    mustBeNumber: value => (value && isNaN(value) ? 'FaqForm_field_a_number' : undefined),
    maxLength: maxLength => value => (value && value.toString().length > maxLength ? 'FaqForm_field_max_length_error' : undefined),
    minLength: minLength => value => (value && value.toString().length < minLength ? `Введите не меньше символов` : undefined),
    minMaxLength: (min, max) => value => (value && (value.toString().length < min || value.toString().length > max) ? `FaqForm_field_range_error` : undefined),
    isEmail: value => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isEmail = re.test(String(value).toLowerCase());
        return value && !isEmail ? 'FaqForm_field_email_error' : undefined ;
        },
    capcha: capchaCode => value => (value !== capchaCode ? 'FaqForm_field_code_error' : undefined)
};

export const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined);

export default ({wrapperClassName = "grid_3", title='', type='text', placeholder='', inputType='text', validate, name, isRequiredLabel = false}) =>  {
    const {t} = useTranslation();
    return (
        <div className={wrapperClassName}>
            <Field name={name} validate={validate} type={type}>
                {({ input, meta }) => {
                    const inputClassName = meta.error && meta.touched ? 'field error' : 'field ';
                    return (
                        <>
                            <label className={'field_title'}>{title}{isRequiredLabel ? <span className={'required'}> *</span> : null}</label>
                            <input {...input} className={inputClassName} type={inputType} placeholder={placeholder}/>
                            {meta.error && meta.touched && <p className={'help_error'}>{t(meta.error)}</p>}
                        </>
                    )
                }}
            </Field>
        </div>
    )
}
