import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./capchaCanvas.module.scss";

class ClientCaptcha extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
        this.captchaCode = "";
    }

    componentDidMount() {
        this.generateCaptcha();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.needUpdateCapcha) {
            this.generateCaptcha();
            this.props.setUpdateCapcha(false);
            return true
        }
    }

    generateCode = () => {
        const { chars, charsCount } = this.props;
        const captcha = [];
        for (let i = 0; i < charsCount; i += 1) {
            const index = Math.ceil(Math.random() * chars.length);
            if (chars[index] && captcha.indexOf(chars[index]) === -1)
                captcha.push(chars[index]);
            else i -= 1;
        }
        return captcha.join("");
    };

    resetCaptcha = e => {
        e.preventDefault();
        this.generateCaptcha();
    };

    generateCaptcha = () => {
        const {
            width,
            height,
            fontSize,
            captchaCode,
            backgroundColor,
            fontFamily,
            fontColor
        } = this.props;
        this.captchaCode = this.generateCode();
        const ctx = this.canvasRef.current.getContext("2d");
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, width, height);
        ctx.font = `${fontSize}px ${fontFamily}`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = fontColor;
        ctx.fillText(this.captchaCode.split("").join(" "), width / 2, height / 2);
        captchaCode(this.captchaCode);
    };

    render() {
        const {
            width,
            height,
            containerClassName,
            retry,
        } = this.props;
        return (
            <div className={containerClassName}>
                <canvas
                    width={width}
                    height={height}
                    ref={this.canvasRef}
                    style={{ pointerEvents: "none" }}
                    //className={styles.retryButton}
                />
                {retry && (
                    <div
                        onClick={this.resetCaptcha}
                        id="retryButton"
                        className={styles.retryButton}
                    >
                    </div>
                )}
            </div>
        );
    }
}

ClientCaptcha.defaultProps = {
    width: 100,
    height: 48,
    fontSize: 22,
    fontFamily: "Arial, Tahoma",
    fontColor: "#000",
    chars: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    charsCount: 4,
    backgroundColor: "#F2F2F2",
    retry: true,
    retryIconSize: 24,
    retryImgClassName: "",
    captchaClassName: "",
    containerClassName: styles.captchaContainer
};

ClientCaptcha.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    fontSize: PropTypes.number,
    fontFamily: PropTypes.string,
    fontColor: PropTypes.string,
    chars: PropTypes.string,
    charsCount: PropTypes.number,
    captchaCode: PropTypes.func.isRequired,
    backgroundColor: PropTypes.string,
    retry: PropTypes.bool,
    retryIcon: PropTypes.string,
    retryIconSize: PropTypes.number,
    retryButtonClassName: PropTypes.string,
    retryImgClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    captchaClassName: PropTypes.string,
    needUpdateCapcha: PropTypes.bool,
    setUpdateCapcha: PropTypes.func
};

export default ClientCaptcha;
